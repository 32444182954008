<script setup lang="ts">
import {useDisplay} from 'vuetify'

const {mdAndDown} = useDisplay()

const shopStore = useShopStore();
const {shopData, orgInfoModal} = storeToRefs(shopStore);


const close = () => {
  orgInfoModal.value = false;
}

</script>
<template>
  <div class="">
    <v-dialog
        v-model="orgInfoModal"
        v-if="shopData"
        activator="parent"
        class="h-max"
    >
      <v-row dense style="">
        <v-col cols="12">
          <v-card class="py-2 px-2 h-100">
            <v-row>
              <v-col cols="3">
                <v-btn
                    icon="mdi-close"
                    @click=" orgInfoModal= false"
                    variant="flat"
                ></v-btn>
              </v-col>
              <v-col cols="6">
                <v-row justify="center" align="center" class="py-5 px-5">
                  <div class="product-title text-center">
                    <v-img class="" v-if="shopData.logoImage" :src="shopData.logoImage" alt=""></v-img>
                    <div class="mr-5 d-inline float-left">
                      {{ shopData.name }}
                    </div>
                  </div>
                </v-row>
              </v-col>
            </v-row>
            <div class="product">
              <v-img class="" v-if="shopData.bannerImage" :src="shopData.bannerImage" alt=""></v-img>
              <div class="d-flex justify-center w-full">
                <div class="">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                  scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
                  release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                  software like Aldus PageMaker including versions of Lorem Ipsum
                </div>
              </div>
              <v-card-text class="text-center py-2">
              </v-card-text>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>
  </div>
</template>

<style scoped>
</style>